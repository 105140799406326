<template>

  <div>
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>填报信息汇总
            <small class="text-muted"></small>
          </h2>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <el-divider></el-divider>
              <el-form ref="form" label-width="300px" style="margin: 5% 0;">

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="导出表类型" required="">
                      <el-radio-group v-model="query_type">
                       <!-- <el-radio-button label="有效项目信息"></el-radio-button> -->
                        <el-radio-button label="跟踪洽谈项目信息"></el-radio-button>
                        <el-radio-button label="入园预审项目信息"></el-radio-button>
                        <el-radio-button label="签约项目信息"></el-radio-button>
                        <el-radio-button label="注册项目信息"></el-radio-button>
                        <el-radio-button label="驻外招商信息"></el-radio-button>
                        <el-radio-button label="接待客商信息"></el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>

                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="审批状态" required="">
                      <el-radio-group v-model="query_approval">
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button label="待审批"></el-radio-button>
                        <el-radio-button label="审批通过"></el-radio-button>
                        <el-radio-button label="审批未通过"></el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="日期范围" required="">

                      <el-date-picker v-model="query_time_start" align="left" type="date"
                                      placeholder="起始日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
                                      value-format="yyyy-MM-dd">
                      </el-date-picker>
                      -
                      <el-date-picker v-model="query_time_end" align="left" type="date"
                                      placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
                                      value-format="yyyy-MM-dd">
                      </el-date-picker>

                    </el-form-item>
                  </el-col>

                </el-row>

                <el-row v-if="role.depts && role.depts.length>0">
                  <el-col :span="24">
                    <el-form-item label="部门选择">
                      <el-select v-model="query_dept" filterable clearable>
                        <el-option v-for="(dept,index) in depts" :index="index"
                                   v-if="role.type==='system' ||  role.depts.indexOf(dept.id)>=0" :label="dept.name"
                                   :value="dept.name"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="" required="">
                      <el-button type="primary" style="width: 30%;height: 50px;" size="medium" icon="el-icon-upload"
                                 @click="submit">导出
                      </el-button>
                    </el-form-item>
                  </el-col>

                </el-row>
              </el-form>
              <el-divider></el-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import deptController from "@/controller/deptController";
import {download} from "@/plugins/axios";

export default {
  created() {
    this.role = JSON.parse(sessionStorage.getItem('role'))
    this.initDepts();
  },
  data() {
    return {
      depts: [],
      query_type: '',
      query_dept: null,
      query_approval: '全部',
      query_time_start: '',
      query_time_end: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {
    initDepts() {
      deptController.list().then(res => {
        this.depts = res.data;
      });
    },
    async submit() {

      if (!this.query_type) {
        this.$message.error('请选择导出表类型')
        return;
      }

      if (!this.query_approval) {
        this.$message.error('请选择审批状态')
        return;
      }

      if (!this.query_time_start || !this.query_time_end) {
        this.$message.error('请选择时间范围')
        return;
      }

      await download(new Date().toDateString()+".xlsx", `/api/collect/download-report`, {
        query_time_start: this.query_time_start,
        query_time_end: this.query_time_end,
        query_approval: this.query_approval,
        query_type: this.query_type,
        query_dept: this.query_dept ? this.base64Encode(this.query_dept) : ''
      });

      // window.location.href = `/api/collect/download-report?query_time_start=${this.query_time_start}&query_time_end=${this.query_time_end}&query_approval=${this.query_approval}&query_type=${this.query_type}&query_dept=${this.query_dept?this.base64Encode(this.query_dept):''}`;
    }
  }

}
</script>

<style>
</style>
